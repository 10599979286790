








































import PicArchive from '@/components/UI/pictures/PicArchive.vue'
import PicMagnet from '@/components/UI/pictures/PicMagnet.vue'
import { Tab } from '@/store/modules/chatsBar/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    PicArchive,
    PicMagnet,
  },
})
export default class Placeholder extends Vue {
  @Prop({ type: String, required: true }) private readonly tab!: Tab
  @Prop({ type: Boolean }) private readonly isUnreadOnlyDone!: boolean

  private get pictureComponent () {
    return this.tab === 'unreadOnly' ? 'PicMagnet' : 'PicArchive'
  }

  private get text () {
    if (this.tab === 'unreadOnly' && this.isUnreadOnlyDone) {
      return this.$t('chatsBar.placeholder.emptyUnreadOnly').toString()
    }

    if (this.tab === 'hidden') {
      return this.$t('chatsBar.placeholder.emptyHidden').toString()
    }

    return this.$t('chatsBar.placeholder.empty').toString()
  }

  private get caption () {
    if (this.tab === 'unreadOnly') {
      return this.isUnreadOnlyDone
        ? this.$t('chatsBar.placeholder.unreadOnlyDone').toString()
        : this.$t('chatsBar.placeholder.unreadOnlyEmpty').toString()
    }

    if (this.tab === 'hidden') {
      return this.$t('chatsBar.placeholder.hiddenHelp').toString() +
        ` «<span class="text-no-wrap">${
          this.$t('chatsBar.list.menu.hide').toString()
        }</span>»`
    }
  }

  private get showCreateDirectBtn () {
    return this.tab === 'all' || this.tab === 'direct'
  }

  private get showCreateGroupBtn () {
    return this.tab === 'all' || this.tab === 'group'
  }
}
